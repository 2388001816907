<template>
  <div class="custmoerBox">
    <pcHeader />
    <div class="custmbg">
      <img src="../assets/custmobg.jpg" />
      <div class="banner-title">
        {{ $t("lang.customerService.bannerTitle") }}
      </div>
    </div>
    <div class="custmDetail">
      <div class="custmdiv">{{ $t("lang.customerService.custmdiv") }}</div>
      <div class="part">
        <div class="part_left">
          <div class="title">{{ $t("lang.customerService.titleOne") }}</div>
          <div class="parps">{{ $t("lang.customerService.parpsOne") }}</div>
          <div class="title" style="margin-top: 60px">
            {{ $t("lang.customerService.titleTwo") }}
          </div>
          <div class="parps">{{ $t("lang.customerService.parpsTwo") }}</div>
        </div>
        <div class="part_right">
          <img src="../assets/custmocount.jpg" />
        </div>
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
};
</script>
<style lang="scss">
.custmoerBox {
  .custmbg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .custmDetail {
    width: 1200px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 120px;
    .custmdiv {
      font-weight: bold;
      font-size: 20px;
      color: #6d7276;
    }
    .part {
      display: flex;
      //flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;
      .title {
        font-size: 26px;
        font-weight: bold;
      }
      .parps {
        font-size: 18px;
        color: #6d7276;
        margin-top: 10px;
      }
      .part_right {
        margin-right: 100px;
        margin-top: 20px;
      }
    }
  }
}
</style>
